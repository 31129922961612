import { injectable } from 'inversify';
import ValidationMessage from '../../models/ValidationMessage';
import { IUserValidator } from '../IUserValidator';

@injectable()
export class UserValidator implements IUserValidator {
    validatePasswordConfirm(
        password: string,
        passwordConfirm: string
    ): ValidationMessage {
        if (password !== passwordConfirm) {
            return {
                isSuccess: false,
                message: 'Passwords do not match',
            };
        }

        return {
            isSuccess: true,
            message: '',
        };
    }

    validateGamerTag(gamerTag: string): ValidationMessage {
        if(!gamerTag){
            console.log('here 1')
            return {
                isSuccess: false,
                message: 'Tag Name cannot be empty!',
            };
        }
        if (gamerTag.length <= 3) {
            
            console.log('here 3')
            return {
                isSuccess: false,
                message: 'Tag Name too short!',
            };
        }
        if (gamerTag.length > 15) {
            return {
                isSuccess: false,
                message: 'Tag Name too long!',
            };
        }
    
        let hasLetter = false;
        for (let i = 0; i < gamerTag.length; i++) {
            if (gamerTag[i].toLowerCase() !== gamerTag[i].toUpperCase()) {
                hasLetter = true;
                continue;
            }
            if (/[0-9]/.test(gamerTag[i])) {
                continue;
            }
            return {
                isSuccess: false,
                message: 'Tag Name must only contain letters and numbers!',
            };
        }
    
        if (!hasLetter) {
            return {
                isSuccess: false,
                message: 'Tag Name must contain at least one letter!',
            };
        }
    
        return {
            isSuccess: true,
            message: '',
        };
    }


    validateEmail(email: string): ValidationMessage {
        const regex = new RegExp(
            /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
        );

        if (regex.test(email)) {
            return {
                isSuccess: true,
                message: '',
            };
        }

        return {
            isSuccess: false,
            message: 'Enter a valid email!',
        };
    }

    validatePassword(
        password: string,
        passwordConfirm: string
    ): ValidationMessage {
        if (password !== passwordConfirm) {
            return {
                isSuccess: false,
                message: 'Passwords do not match!',
            };
        }

        const passwordValidator =
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]).{8,}$/;
        if (passwordValidator.test(password)) {
            return {
                isSuccess: true,
                message: '',
            };
        }

        return {
            isSuccess: false,
            message:
                'Password should be at least 8 characters in length and should include at least one upper case letter, one number, and one special character.',
        };
    }
}
