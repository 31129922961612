import { useInjection } from 'inversify-react';
import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IAuthService } from '../../../services/IAuthService';
import IUIService from '../../../services/IUIService';
import { IUserValidator } from '../../../services/IUserValidator';
import { LoginState } from '../../../models/LoginState';
import ConfirmAccountComponent from '../../confirm-account-component/confirm-account.component';
import AuthPageWrapper from '../../auth-page-wrapper/auth-page-wrapper.component';
import styles from './login-panel.module.scss';
import LoginPanelHeader from '../../login-panel-header/login-panel-header.component';
import ErrorContainer from '../../error-container/error-container.component';
import KDMRecaptchaWrapper from '../../kdm-recaptcha-wrapper/kdm-recaptcha-wrapper.component';
import { IAuthGateRESTClient } from '../../../rest-clients/IAuthGateRESTClient';
import { IWindowMessage } from '../../../services/IWindowMessage';

enum LoginPageFromStoreState {
    Default,
    NeedsConfirmLogin,
    NeedsConfirmRegister,
    LoginSuccess,
}

type StateUpdateFunction = (
    state: LoginPageFromStoreState,
    email?: string,
    password?: string
) => void;

export default function LoginPageFromStore() {
    const authService = useInjection<IAuthService>('IAuthService');
    const [pageState, setPageState] = useState(
        LoginPageFromStoreState.Default
    );

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const windowMessage = useInjection<IWindowMessage>('IWindowMessage');

    const headerText = () => {
        switch (pageState) {
            case LoginPageFromStoreState.Default:
                return (
                    <>
                        Create an account to <br /> continue this purchase.
                    </>
                );
            case LoginPageFromStoreState.NeedsConfirmLogin:
                return (
                    <>
                        Do Not Close This Page! <br /> Please Confirm Your
                        Account
                    </>
                );
            case LoginPageFromStoreState.NeedsConfirmRegister:
                return (
                    <>
                        Do Not Close This Page! <br /> Account Created
                        Successfuly.
                    </>
                );
            case LoginPageFromStoreState.LoginSuccess:
                return (
                    <>
                        Do Not Close This Page! <br /> Login Success
                    </>
                );
            default:
                return (
                    <>
                        Create an account to <br /> continue this purchase.
                    </>
                );
        }
    };

    const message = () => {
        switch (pageState) {
            case LoginPageFromStoreState.NeedsConfirmLogin:
                return "Please don't close this page. Confirm your account via link in your email, before you can continue with your purchase!";
            case LoginPageFromStoreState.NeedsConfirmRegister:
                return "Please don't close this page. Confirm your account via link in your email, before you can continue with your purchase!";
        }
        return "Please don't close this page. Confirm your account via link in your email, before you can continue with your purchase!";
    };

    const resend = () => {
        if (email === '') return;
        authService.resendEmail(email, 'store');
    };

    const startTimer = (
        localEmail : string,
        localPassword: string
    )=>{
        const timer = setInterval(async () => {
            const login = await authService.loginJWT(localEmail, localPassword);
            if (!login) return;
            console.log('MESSAGE TO SPAWNER');
            windowMessage.postMessageToSpawner({
                key: 'jwt',
                value: login.jwt,
            });
            clearInterval(timer);
        }, 1000);
    }

    const updateState: StateUpdateFunction = (
        state: LoginPageFromStoreState,
        localEmail?: string,
        localPassword?: string
    ) => {

        if (!!localEmail) {setEmail(localEmail);}
        if (!!localPassword){ setPassword(localPassword);}

        setPageState(state);

        if (state === LoginPageFromStoreState.NeedsConfirmLogin) {
            resend();
        }

        if (
            state === LoginPageFromStoreState.NeedsConfirmLogin ||
            state === LoginPageFromStoreState.NeedsConfirmRegister
        ) {
           startTimer(localEmail!, localPassword!);
        }
    };

    return (
        <AuthPageWrapper indent={5}>
            <div className={styles.middleColumn}>
                <div className="header-container">
                    <LoginPanelHeader />
                </div>
                <div className={styles.indent}></div>
                <div className={styles.headerText}>{headerText()}</div>
                <div className={styles.indent_second}></div>
                {pageState === LoginPageFromStoreState.Default && (
                    <RegistrationSectionForStore updateState={updateState} />
                )}
                {pageState === LoginPageFromStoreState.NeedsConfirmLogin && (
                    <ConfirmAccountComponent
                        message={message()}
                        resend={resend}
                        timeout={true}
                    />
                )}
                {pageState === LoginPageFromStoreState.NeedsConfirmRegister && (
                    <ConfirmAccountComponent
                        message={message()}
                        resend={resend}
                        timeout={true}
                    />
                )}
                {pageState === LoginPageFromStoreState.LoginSuccess && (
                    <>You have successfully logged in. <br/> This page will automatically close.</>
                )}
            </div>
        </AuthPageWrapper>
    );
}

function RegistrationSectionForStore({
    updateState,
}: {
    updateState: StateUpdateFunction;
}) {
    const userValidator = useInjection<IUserValidator>('IUserValidator');
    const uiService = useInjection<IUIService>('IUIService');

    const authGateRestClient = useInjection<IAuthGateRESTClient>(
        'IAuthGateRESTClient'
    );

    let recaptcha = useRef<KDMRecaptchaWrapper | null>();
    const [email, setEmail] = useState('');
    const [gamerTag, setGamerTag] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [generalError, setGeneralError] = useState('');
    const [gamerTagError, setGamerTagError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
    const [loginVisible, setLoginVisible] = useState(false);

    const registerClick = async () => {
        if (!formIsValid()) return;
        let captcha: string | null = '';

        if (!recaptcha.current) return;

        try {
            captcha = await recaptcha.current.executeAsync();
        } catch {
            setGeneralError('Invalid captcha');
            return;
        }

        if (!captcha) {
            setGeneralError('Invalid captcha');
            return;
        }
        uiService.showLoading();

        const result = await authGateRestClient.register(
            email,
            'store',
            password,
            captcha,
            '6LcoHMsfAAAAAARqmy9Iyxpbt1K_Wq_Pjbgn9S5r',
            gamerTag
        );
        uiService.hideLoading();
        if (!result || (result && result.isSuccess)) {
            updateState(
                LoginPageFromStoreState.NeedsConfirmRegister,
                email,
                password
            );

            if(!!result && result.isSuccess && !!result.generalError){
                uiService.showErrorNotification(result.generalError);
            }

        } else {
            if (result.emailError) {
                setEmailError(result.emailError);
                return;
            }
            if (result.passwordError) {
                setPasswordError(result.passwordError);
                return;
            }
            if (result.generalError) {
                setGeneralError(result.generalError);
                return;
            }
            setGeneralError('Invalid information!');
        }
    };

    const formIsValid = () => {
        const emailValidation = userValidator.validateEmail(email);
        const passwordValidation = userValidator.validatePassword(
            password,
            confirmPassword
        );

        const gamerTagValidation = userValidator.validateGamerTag(gamerTag);

        setGeneralError('');
        setEmailError('');
        setPasswordError('');
        setGamerTagError('');
        if (!acceptTerms) {
            setGeneralError('Please accept terms and conditions!');
            return false;
        }
        if (!emailValidation.isSuccess && email && email.length > 0) {
            setEmailError(emailValidation.message);
            return false;
        }
        if (!gamerTagValidation.isSuccess && gamerTag && gamerTag.length > 0) {
            setGamerTag(gamerTagValidation.message);
            return false;
        }
        if (!passwordValidation.isSuccess && password && password.length > 0) {
            setPasswordError(passwordValidation.message);
            return false;
        }
        return (
            emailValidation.isSuccess &&
            password.length > 0 &&
            passwordValidation.isSuccess
        );
    };

    const buttonEnabled =
        userValidator.validateEmail(email).isSuccess &&
        password.length > 0 &&
        confirmPassword.length > 0;
    return (
        <React.Fragment>
            <React.Fragment>
                <div className="login-dialog-row input-row">
                    <input
                        type="text"
                        id="wod-gamertag"
                        className={!!gamerTagError ? 'has-error' : undefined}
                        value={gamerTag}
                        onChange={(e) => {
                            e.preventDefault();
                            setGamerTag(e.target.value);
                        }}
                        placeholder="Gamer Tag"
                    />
                </div>

                <ErrorContainer error={gamerTagError} />

                <div className="login-dialog-row input-row">
                    <input
                        type="email"
                        id="wod-email"
                        className={!!emailError ? 'has-error' : undefined}
                        value={email}
                        onChange={(e) => {
                            e.preventDefault();
                            setEmail(e.target.value);
                        }}
                        placeholder="Email"
                    />
                </div>
                <ErrorContainer error={emailError} />

                <div className="login-dialog-row input-row">
                    <input
                        type="password"
                        id="wod-password"
                        className={!!passwordError ? 'has-error' : undefined}
                        value={password}
                        onChange={(e) => {
                            e.preventDefault();
                            setPassword(e.target.value);
                        }}
                        placeholder="Password"
                    />
                </div>

                <div className="login-dialog-row input-row">
                    <input
                        type="password"
                        id="wod-password"
                        className={!!passwordError ? 'has-error' : undefined}
                        value={confirmPassword}
                        onChange={(e) => {
                            e.preventDefault();
                            setConfirmPassword(e.target.value);
                        }}
                        placeholder="Confirm Password"
                    />
                </div>
                <ErrorContainer error={passwordError} />
                <div className="login-dialog-row input-row terms-row">
                    <label>
                        <input
                            type="checkbox"
                            id="wod-terms"
                            className={
                                !!passwordError ? 'has-error' : undefined
                            }
                            checked={acceptTerms}
                            onChange={(e) => {
                                setAcceptTerms(e.target.checked);
                            }}
                        />
                        <span>
                            Accept{' '}
                            <a
                                rel="noreferrer"
                                href="https://kingdomdeath.com/legal/terms"
                                target="_blank"
                            >
                                terms and conditions
                            </a>
                        </span>
                    </label>
                </div>
                <ErrorContainer error={generalError} />

                <div className="button-container login-dialog-row input-row">
                    <input
                        type="button"
                        onClick={registerClick}
                        id="wod-login-btn"
                        value="Register"
                        disabled={!buttonEnabled}
                    />
                </div>

                <KDMRecaptchaWrapper
                    ref={(e) => (recaptcha.current = e)}
                    sitekey="6LcoHMsfAAAAAARqmy9Iyxpbt1K_Wq_Pjbgn9S5r"
                    size="invisible"
                />

                <div
                    className="login-dialog-row forgot-password-section"
                    style={{ marginBottom: '20px' }}
                >
                    <p>
                        Already have an account?{' '}
                        <a
                            href="#login"
                            onClick={(e) => {
                                e.preventDefault();
                                if (!loginVisible) {
                                    setLoginVisible(true);
                                }
                            }}
                        >
                            Login instead!
                        </a>
                    </p>
                </div>
            </React.Fragment>

            {!!loginVisible && (
                <React.Fragment>
                    <LoginSectionForStore updateState={updateState} />
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

function LoginSectionForStore({
    updateState,
}: {
    updateState: StateUpdateFunction;
}) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const authService = useInjection<IAuthService>('IAuthService');
    const windowMessage = useInjection<IWindowMessage>('IWindowMessage');
    const uiService = useInjection<IUIService>('IUIService');

    const hasError = !!error && error !== '';

    const loginClick = async () => {
        uiService.showLoading();
        const response = await authService.login(email, password);
        uiService.hideLoading();
        if (response === LoginState.Success) {
            windowMessage.postMessageToSpawner({
                key: 'jwt',
                value: await authService.getToken(),
            });
            windowMessage.postMessageToSpawner({
                key: 'detailed',
                value: authService.getTokenLocal(),
            });
            updateState(LoginPageFromStoreState.LoginSuccess, email, password);
        } else {
            if (response === LoginState.NeedsActivation) {
                updateState(LoginPageFromStoreState.NeedsConfirmLogin, email, password);
            } else {
                setError('Invalid email or password!');
            }
        }
    };

    return (
        <React.Fragment>
            <div className="login-dialog-row input-row">
                <input
                    type="email"
                    id="wod-email"
                    className={hasError ? 'has-error' : undefined}
                    value={email}
                    onChange={(e) => {
                        e.preventDefault();
                        setEmail(e.target.value);
                    }}
                    placeholder="Email"
                />
            </div>

            <div className="login-dialog-row input-row">
                <input
                    type="password"
                    id="wod-password"
                    className={hasError ? 'has-error' : undefined}
                    value={password}
                    onChange={(e) => {
                        e.preventDefault();
                        setPassword(e.target.value);
                    }}
                    placeholder="Password"
                />
            </div>
            <div
                className="login-dialog-row input-row error-container"
                style={{
                    display: !hasError ? 'none' : 'flex',
                }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    fill="red"
                    viewBox="0 0 16 16"
                >
                    <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                </svg>
                <p>{error}</p>
            </div>

            <div className="button-container login-dialog-row input-row">
                <input
                    type="button"
                    onClick={loginClick}
                    id="wod-login-btn"
                    value="Log In"
                    disabled={email === '' || password === ''}
                />
            </div>

            <div className="login-dialog-row forgot-password-section">
                <Link to="/forgot-password" rel="noreferrer" target="_blank">
                    Forgot Password?
                </Link>
            </div>
        </React.Fragment>
    );
}
